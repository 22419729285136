<template>
  <div class="sign_up">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="600"
            width="600"
            class="pa-5"
          >
            <h3 class="text-h5">
              Subscribe
            </h3>

            <v-alert
              dense
              border="left"
              type="info"
            >
              Registration is currently closed.
            </v-alert>

            <v-alert
              v-if="errors.non_field_errors"
              dense
              border="left"
              type="warning"
            >
              <template v-if="errors.non_field_errors.length === 1">
                {{ errors.non_field_errors[0] }}
              </template>

              <ul v-else>
                <li
                  v-for="(error, index) of errors.non_field_errors"
                  :key="`error${index}`"
                >
                  {{ error }}
                </li>
              </ul>
            </v-alert>

            <form
              ref="signUpForm"
              style="display: none;"
              @submit.prevent="submit"
            >
              <v-row class="mt-0">
                <div class="col-sm-6 py-0">
                  <v-text-field
                    v-model="formFields.first_name"
                    label="First name"
                    :error-messages="errors.first_name"
                    required
                    data-recurly="first_name"
                  />
                </div>

                <div class="col-sm-6 py-0">
                  <v-text-field
                    v-model="formFields.last_name"
                    label="Last name"
                    :error-messages="errors.last_name"
                    required
                    data-recurly="last_name"
                  />
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-6 py-0">
                  <v-text-field
                    v-model="formFields.email"
                    label="Email"
                    type="email"
                    :error-messages="errors.email"
                    required
                  />
                </div>

                <div class="col-sm-6 py-0">
                  <v-text-field
                    v-model="formFields.phone"
                    label="Phone number"
                    type="tel"
                    :error-messages="errors.phone"
                    required
                  />
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-6 py-0">
                  <v-text-field
                    v-model="formFields.password"
                    label="Password"
                    :error-messages="errors.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                </div>

                <div class="col-sm-6 py-0">
                  <v-text-field
                    v-model="formFields.confirm_password"
                    label="Confirm password"
                    :error-messages="errors.confirm_password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-6 py-0">
                  <v-text-field
                    label="Address line 1"
                    :error-messages="errors.address1"
                    required
                    data-recurly="address1"
                  />
                </div>

                <div class="col-sm-6 py-0">
                  <v-text-field
                    label="Address line 2"
                    :error-messages="errors.address2"
                    data-recurly="address2"
                  />
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-4 py-0">
                  <v-text-field
                    label="City"
                    :error-messages="errors.city"
                    required
                    data-recurly="city"
                  />
                </div>

                <div class="col-sm-4 py-0">
                  <v-select
                    v-model="state"
                    :items="states"
                    label="State"
                    :error-messages="errors.state"
                  />

                  <input
                    type="hidden"
                    :value="state"
                    data-recurly="state"
                  >
                </div>

                <div class="col-sm-4 py-0">
                  <v-text-field
                    label="Postal code"
                    :error-messages="errors.postal_code"
                    required
                    data-recurly="postal_code"
                  />
                </div>

                <input
                  type="hidden"
                  value="US"
                  data-recurly="country"
                >
              </v-row>

              <v-row>
                <div class="col-sm-12 py-0">
                  <template v-if="subscriptionPlansStatus === 'loading'">
                    Loading subscription plans

                    <v-progress-circular
                      indeterminate
                      :width="1"
                      :size="16"
                    />
                  </template>

                  <template v-else-if="subscriptionPlansStatus === 'loaded'">
                    <p :class="{ 'error--text': errors.subscription_plan !== undefined }">
                      Choose a subscription plan
                    </p>

                    <v-item-group
                      v-model="formFields.subscription_plan"
                      class="mt-3"
                    >
                      <v-row>
                        <v-col
                          v-for="subscriptionPlan of subscriptionPlans"
                          :key="`subscriptionPlan${subscriptionPlan.code}`"
                          cols="12"
                          md="6"
                        >
                          <v-item v-slot="{ active, toggle }">
                            <button
                              type="button"
                              class="subscription-plan rounded-lg elevation-2 pa-3"
                              :class="{
                                'primary': active,
                                'subscription-plan-selected': active,
                              }"
                              @click="toggle"
                            >
                              <div class="subscription-plan-name">
                                {{ subscriptionPlan.name }}
                              </div>

                              <div class="subscription-plan-price mb-3">
                                ${{ formatCurrency(subscriptionPlan.price_per_month) }} / month
                              </div>

                              {{ formatNumber(subscriptionPlan.max_records_per_download) }}
                              records per download<br>
                              Unlimited downloads
                            </button>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-item-group>
                  </template>

                  <template v-else-if="subscriptionPlansStatus === 'error'">
                    <v-alert
                      dense
                      border="left"
                      type="error"
                    >
                      Unable to load the subscription plans. Please check your
                      connection and try again.
                    </v-alert>

                    <div class="text-right">
                      <v-btn @click="loadRecurlyScript">
                        Try Again
                      </v-btn>
                    </div>
                  </template>
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-12 py-0">
                  <template v-if="recurlyScriptStatus === 'loading'">
                    Loading payment form

                    <v-progress-circular
                      indeterminate
                      :width="1"
                      :size="16"
                    />
                  </template>

                  <div
                    v-else-if="recurlyScriptStatus === 'loaded'"
                    id="card-details"
                  />

                  <template v-else-if="recurlyScriptStatus === 'error'">
                    <v-alert
                      dense
                      border="left"
                      type="error"
                    >
                      Unable to load the payment form. Please check your
                      connection and try again.
                    </v-alert>

                    <div class="text-right">
                      <v-btn @click="loadRecurlyScript">
                        Try Again
                      </v-btn>
                    </div>
                  </template>
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-12 py-0">
                  <v-checkbox
                    v-model="formFields.agree"
                    :error-messages="errors.agree"
                  >
                    <template v-slot:label>
                      <div>
                        I have read and agree to abide by the
                        <a
                          href="/terms-and-conditions/"
                          target="_blank"
                          @click.stop
                        >
                          terms and conditions</a>
                        of Unlimited Mailing Lists
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </v-row>

              <v-row>
                <div class="col-sm-12 py-0 text-center">
                  <a
                    href="https://verify.authorize.net/anetseal/?pid=8a79402b-9ffe-4098-9505-cfc311301f9f&rurl=https://www.unlimitedmailinglists.com"
                    target="_blank"
                  >
                    <img
                      src="@/assets/authorize.net-seal.png"
                      alt="Authorize.Net Verified Merchant"
                    >
                  </a>
                </div>
              </v-row>

              <div
                v-if="recurlyScriptStatus === 'loaded'"
                class="text-right mt-3"
              >
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="submitting"
                  class="mt-2"
                >
                  Subscribe
                  <v-progress-circular
                    v-if="submitting"
                    size="20"
                    width="2"
                    indeterminate
                    class="ml-2"
                  />
                </v-btn>
              </div>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subscriptionPlansStatus: 'loading',
      subscriptionPlans: [],
      recurlyScriptStatus: 'loading',
      submitting: false,
      errors: {},
      formFields: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        subscription_plan: undefined,
        agree: false,
      },
      showPassword: false,
      state: '',
      states: [
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District of Columbia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
      ],
      recurlyElements: null,
    };
  },
  watch: {
    errors() {
      if (!this.isObjectEmpty(this.errors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  metaInfo: {
    title: 'Subscribe',
  },
  created() {
    this.loadSubscriptionPlans();
    this.loadRecurlyScript();
  },
  methods: {
    async loadSubscriptionPlans() {
      this.subscriptionPlansStatus = 'loading';

      const responseData = await this.api({
        url: 'subscription_plans/',
      });

      if (responseData.status === 200) {
        this.subscriptionPlans = responseData.body;
        this.subscriptionPlansStatus = 'loaded';
      } else {
        this.subscriptionPlansStatus = 'error';
      }
    },
    loadRecurlyScript() {
      this.recurlyScriptStatus = 'loading';

      const recurlyScript = document.getElementById('recurly-script');

      if (recurlyScript) {
        document.body.removeChild(recurlyScript);
      }

      const script = document.createElement('script');
      script.id = 'recurly-script';
      script.src = 'https://js.recurly.com/v4/recurly.js';
      script.onerror = () => {
        this.recurlyScriptStatus = 'error';
      };
      script.onload = () => {
        window.recurly.configure('ewr1-7TOwEGycYLntlWHFonGX73');
        this.recurlyScriptStatus = 'loaded';

        this.$nextTick(() => {
          this.recurlyElements = window.recurly.Elements();
          const cardElement = this.recurlyElements.CardElement();
          cardElement.attach('#card-details');
        });
      };
      document.body.appendChild(script);
    },
    submit() {
      this.errors = {};
      this.submitting = true;

      window.recurly.token(this.recurlyElements, this.$refs.signUpForm, async (err, token) => {
        if (err) {
          this.submitting = false;

          const errors = {};

          err.details.forEach((detail) => {
            if (['number', 'month', 'year', 'cvv'].includes(detail.field)) {
              if (errors.non_field_errors === undefined) {
                errors.non_field_errors = [];
              }

              detail.messages.forEach((message) => {
                let fullMessage = 'The "';

                if (detail.field === 'number') {
                  fullMessage += 'Card number';
                } else if (detail.field === 'month') {
                  fullMessage += 'Card expiration month';
                } else if (detail.field === 'year') {
                  fullMessage += 'Card expiration year';
                } else if (detail.field === 'cvv') {
                  fullMessage += 'Card CVV';
                }

                fullMessage += `" field ${message}.`;

                errors.non_field_errors.push(fullMessage);
              });
            } else {
              errors[detail.field] = [];

              detail.messages.forEach((message) => {
                errors[detail.field].push(`This field ${message}.`);
              });
            }
          });

          this.errors = errors;
        } else {
          const postData = JSON.parse(JSON.stringify(this.formFields));
          postData.recurly_token = token.id;

          if (postData.subscription_plan !== undefined) {
            postData.subscription_plan = this.subscriptionPlans[postData.subscription_plan].code;
          }

          const responseData = await this.api({
            url: 'users/',
            method: 'POST',
            json: postData,
          });

          this.submitting = false;

          if (responseData.status === 201) {
            this.$store.commit('userData', responseData.body);
            this.$router.push({ name: 'home' });
          } else if (responseData.status === 400) {
            this.errors = responseData.body;
          } else {
            this.errors = {
              non_field_errors: [
                'Unable to communicate with the server. Please check your '
                + 'connection and try again.',
              ],
            };
          }
        }
      });
    },
  },
};
</script>
